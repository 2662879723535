p {
    font-size: 1.2vw;
    font-weight: 400;
    color: #585858;
}

.hp_background {
    background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(141, 96, 192, 1) 28%, rgba(186, 111, 190, 1) 56%, rgba(196, 84, 84, 1) 74%, rgba(253, 187, 45, 1) 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: #2b215f;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.hp_header {
    display: flex;
    height: 100px;
    background-color: white;
    color: white;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    flex-direction: row;
}

.first_title {
    font-size: 3.6vw;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    color: #ff9225;
    animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.hp_upper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
    font-size: 4.5vw;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    color: #ff9225;
    background-image: url("./imageb.png");
    background-position: bottom;
    background-attachment: fixed;
    background-size: unset;
}

.hp_half {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10%;
    padding-left: 10%;
}

.hp_mid1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
    background-image: url("./midone.webp");
    background-size: cover;
    background-position: center;
    background-color: rgb(255, 255, 255);
    font-size: 2.3vw;
    font-weight: 700;
}

.hp_mid2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
    background-color: rgb(255, 255, 255);
    font-size: 2.3vw;
    font-weight: 700;
}

.hp_mid3 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
    background-color: rgb(233, 233, 233);
    font-size: 2.3vw;
    font-weight: 700;
}

span {
    font-size: 1.3em;
    color: #535353;
}

.hp_mid4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 65vh;
    text-align: center;
    padding-top: 60px;
    background-color: rgb(238, 238, 238);
    font-size: 3.6em;
    font-weight: 400;
}

.hp_mid5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    width: 100vw;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 60px;
    background-color: rgb(255, 255, 255);
    font-size: 3.9em;
    font-weight: 700;
}

.hp_mid5_title {
    font-size: 0.5em;
    margin-left: 20%;
    margin-right: 20%;
}

.hp_mid5_container {
    display: flex;
    flex-direction: column;
    width: 30vw;
    padding-left: 6%;
    padding-right: 6%;
    align-items: center;
    text-align: center;
    font-size: 35px;
    min-height: 300px;
    color: black;
}

.hp_mid5_items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 35px;
    min-height: 300px;
    color: black;
}

.hp_mid5_item_title {
    font-size: 20px;
    font-weight: 700;
    color: #A5BD25;
}

.hp_mid5_subtitle {
    margin-top: 30px;
    font-weight: 700;
    color: #646464;
    font-size: 0.4em;
    margin-left: 10%;
    margin-right: 10%;
}

.hp_collapse {
    display: flex;
    background-color: rgb(238, 238, 238);
    flex-direction: column;
    align-items: center;
    font-size: larger;
}

.hp_logo {
    height: 150px;
}

.hp_footer {
    display: flex;
    flex: 1;
    background-color: white;
    color: black;
    min-height: 70vh;
}

.hp_footer_1 {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: bold;
    font-size: 90%;
    background-color: white;
}

.hp_footer_2 {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: space-between;
}

.hp_footer_3 {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.text_button {
    color: white;
}